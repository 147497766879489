
import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import Home from "./components/pages/Home";
import Apply from "./components/pages/Apply";
import Contact from "./components/pages/Contact";



import Footer from "./components/inc/Footer";
import Gallery from './components/pages/Gallery';

function App() {
  return (
    <Router>
      
  
    
        <div>
      
         
          
          <Routes>
     
         
          <Route axact path="/" element={<Home/>}/>
          <Route axact path="/Online_Apply" element={<Apply/>}/>
             <Route axact path="/contact" element={<Contact/>}/>
             <Route axact path="/Gallery" element={<Gallery/>}/>

          
          </Routes>
    <Footer/>
  
    </div>
 
    </Router>
  );
}

export default App;


import React from 'react'
import Navbar from './../inc/Navbar';
import AddOnline from '../AddOnline';
import {Link} from "react-router-dom";

function Apply() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <div className="album py-2">

        <div className="container">

          <div className="row">
            <Link to="/">
            <button className="button20" type="Submit"><svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg> BACK
            </button>
            </Link>
          </div>
        </div>
      </div>
      <h2 style={{ color: "purple" }}><center><b>NADIA COLLEGE OF PHARMACY</b></center></h2>
      <h3 style={{ color: "purple" }}><center><b>Online Application</b></center></h3>
      <AddOnline />
    </div>
  )
}

export default Apply



import React from 'react';
import {Link} from 'react-router-dom';


import MRINDIANCODER from "../images/mr.indiancoder.jpg";
import "./Footer.css";
function Footer() {
    return(
    
      <div className="srijacollegeofpharmacy">
         <br></br>
         <br/>
        
    <div className="album py-3">

        <div className="container">

            <div className="row">
            <div className="col-md-3">
              
                 <h6 style={{color:"white"}}>Address</h6>
                   <hr/>
                    
                     <ul style={{color:"white"}} className="list-unstyled">
                     <p style={{color:"white"}} className="card-text mb-2">Vill + Po - Narayanpur, Ps- Thanarpara </p>
                                <p style={{color:"white"}} className="card-text mb-2">Dist- Nadia, 741165 WB India</p>
                   
<a href="tel: +91 8145049014"  className="btn btn-secondary mb-2">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg> +91 8145049014</a>
<br/>
<a href="mailto: nadiapharmacy23@gmail.com" className="btn btn-secondary"> nadiapharmacy23@gmail.com</a> 

          </ul>
          </div>

          <div className="col-md-3">
                     <h6 style={{color:"white"}}>Emportant Link</h6>
                     <hr/>
                     <ul className="list-unstyled">
                    
                     
            
           
            <Link  style={{color:"white"}} to="/Gallery">
            <li style={{color:"white"}} className="mb-2"><small>Gallery</small></li></Link>
      
         
        
            
          </ul>
                     
                 </div>
                 <div className="col-md-3">
                 
                     <h6 style={{color:"white"}}>Contact Us</h6>
                     <hr/>

                     <ul style={{color:"white"}} className="list-unstyled">
                  
                     <Link  style={{color:"white"}} to="/contact">
                     <li style={{color:"white"}}  className="mb-2"><small>Contact </small></li></Link>
                     <Link  style={{color:"white"}} to="/aboutus">
                     <li  style={{color:"white"}} className="mb-2"><small>About</small></li></Link>

                     </ul>
                 </div>
                 <div className="col-md-3">
                 <h6 style={{color:"white"}}>Social Link</h6>
                   <hr/>
                     <ul className="list-unstyled">
                     <li className="mb-2"> <a style={{color:"white"}} href="#" target="_blank"  rel="noopener noreferrer">
                 <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" style={{color:"white"}} fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
</svg> <small style={{color:"white"}}> Nadia College Of Pharmacy</small></a> </li>
                           
                           

            
          </ul>
          
          </div>
       
  

        
          
                 </div>
                 </div>
                 </div>
               
              
              
                 
    <div className="album py-3">
        
        <div className="container">

            <div className="row">
            <div className="col-md-4">
      <Link style={{color:"white"}} to="/">
      <p  style={{color:"white"}} >  NADIA COLLEGE OF PHARMACY </p> </Link>
                 </div>

                 <div className="col-md-4">
                 <p  style={{color:"white"}} > Copyright © 2023, SRIJA COLLEGE OF PHARMACY </p>
                  
                 </div>
                 <div className="col-md-4">
                 <small  style={{color:"white"}}> Development & Design By : <a href="https://www.mrindiancoder.com/" target="_blank"  rel="noopener noreferrer"> www.mrindiancoder.com</a> </small>
                <small>  <a  href="https://www.mrindiancoder.com/" target="_blank"  rel="noopener noreferrer">  </a> </small>
                <a href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> <img src={MRINDIANCODER} width="85" height="30" alt="mr.indiancoder"/> </a>
                 </div>
                 
                 </div>
                 </div>
                 </div>
                <br></br>

             </div>
             
    );
}
export default Footer;
import React, { useState } from "react";
import { Form, Alert, InputGroup, Button, Card } from "react-bootstrap";
import BookDataCareer from "../services/book.onlineapply";



const AddOnline = () => {
    const [title, setTitle] = useState("");
    const [castecategorycategary, setCastecategorycategary] = useState("");
    const [gender, setGender] = useState("");
    const [city, setCity] = useState("");
    const [maritalstatus, setMaritalstatus] = useState("");
    const [religion, setReligion] = useState("");
    const [fathersname, setFathersname] = useState("");

    const [mobile, setMobile] = useState("");
    const [emailaddress, setEmailaddress] = useState("");
    const [status, setStatus] = useState("Available");
    const [flag, setFlag] = useState(true);
    const [massage, setMassage] = useState({ error: false, msg: "" });



    const handleSubmit = async (e) => {
        e.preventDefault();
        setMassage("");
        if (title === "" || mobile === "" || emailaddress === "" || city === "" || castecategorycategary === "" || gender === "" || maritalstatus === "" || religion === "" || fathersname === "") {
            setMassage({ error: true, msg: "All fields are mandatory!" });
            return;
        }
        const newBook = {
            title,
            mobile,
            emailaddress,

            city,
            castecategorycategary,
            religion,
            gender,
            maritalstatus,
            fathersname,
            status
        }
        console.log(newBook);

        try {
            await BookDataCareer.addBooks(newBook);
            setMassage({ error: false, msg: "Submited Successfuly!" });
        } catch (err) {
            setMassage({ error: true, msg: err.massage });
        }

        setTitle("");
        setMobile("");
        setEmailaddress("");

        setCity("");
        setCastecategorycategary("");
        setGender("");
        setMaritalstatus("");
        setReligion("");
        setFathersname("");
    };



    return (
        <>

            <br />


            {massage?.msg && (<Alert variant={massage?.error ? "danger" : "success"}
                dismissible
                onClose={() => setMassage("")}
            >
                {""}
                {massage?.msg}
            </Alert>
            )}

            <Form onSubmit={handleSubmit} >
                <div className="album py-2">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookTitle">
                                    <label for="name" className="form-label mb-0"><p><small>Name</small></p></label>

                                    <InputGroup>

                                        <Form.Control type="text"

                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>


                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-4" controlId="formBookMobile">
                                    <label for="contact" className="form-label mb-0"><p><small>Mobile</small></p></label>
                                    <Form.Control type="text"

                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                    />

                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-4" controlId="formBookEmailaddress">
                                    <label for="email" className="form-label mb-0"><p><small>Email</small></p></label>
                                    <Form.Control type="text"

                                        value={emailaddress}
                                        onChange={(e) => setEmailaddress(e.target.value)}
                                    />

                                </Form.Group>

                            </div>


                            <div className="col-md-6">

                                <Form.Group className="mb-4" controlId="formBookCity">
                                    <label for="location" className="form-label mb-0"><p><small>City</small></p></label>
                                    <Form.Control type="text"

                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />

                                </Form.Group>
                            </div>


                            <div className="col-md-6">
                                <Form.Group className="mb-4" controlId="formBookCastecategorycategary">
                                    <label for="catagory" className="form-label mb-0"><p><small>Select Category</small></p></label>

                                    <Form.Control type="text"

                                        placeholder="GEN / OBC-A / OBC-B / SC / ST"
                                        value={castecategorycategary}
                                        onChange={(e) => setCastecategorycategary(e.target.value)}
                                    />

                                </Form.Group>
                            </div>

                          
                            <div className="col-md-6">
                                <Form.Group className="mb-4" controlId="formBookGender">
                                    <label for="gender" className="form-label mb-0"><p><small>Gender</small></p></label>

                                    <Form.Control type="text"

                                        placeholder="MALE, FEMALE, OTHERS"
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                    />

                                </Form.Group>

                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-4" controlId="formBookMaritalstatus">
                                    <label for="maritalstatus" className="form-label mb-0"><p><small>Marital Status</small></p></label>

                                    <Form.Control type="text"

                                        placeholder="Enter Marital status"
                                        value={maritalstatus}
                                        onChange={(e) => setMaritalstatus(e.target.value)}
                                    />

                                </Form.Group>
                            </div>
                            
                            <div className="col-md-6">
                                <Form.Group className="mb-4" controlId="formBookReligion">
                                    <label for="religion" className="form-label mb-0"><p><small>Religion</small></p></label>

                                    <Form.Control type="text"

                                        placeholder="Religion"
                                        value={religion}
                                        onChange={(e) => setReligion(e.target.value)}
                                    />

                                </Form.Group>
                            </div>

                            <div className="col-md-6">
                                <Form.Group className="mb-4" controlId="formBookFathersname">
                                    <label for="fathersname" className="form-label mb-0"><p><small>Father's Name</small></p></label>

                                    <Form.Control type="text"

                                        placeholder="Father's Name"
                                        value={fathersname}
                                        onChange={(e) => setFathersname(e.target.value)}
                                    />

                                </Form.Group>
                            </div>

                            <div class="alert alert-primary" role="alert">
  Admission form will be updated soon!
</div>
                            {/* <Form.Select className="mb-3" controlId="formBookcastecategorycategary">
                
              

        
      
      
                
     
     
     
    
        </Form.Select> */}


                            {/* <Form.Group className="mb-3" controlId="formBookcastecategorycategary">
          <Form.Label>Disabled select menu</Form.Label>
          <Form.Select >
            <option>Animation</option>
            <option>Game 2D </option>
            <option>VFX</option>
          </Form.Select>
             </Form.Group> */}

                            {/* <ButtonGroup aria-lable="Basic example" className="mb-3">
                    <Button 
                    disabled={flag} variant="success" 
                    onClick={(e) => {
                    setStatus("Available");
                    setFlag(true);
                    }}
                    >
                        Available
                        </Button>
                        <Button variant="danger" 
                        onClick={(e) => {
                        setStatus("Not Available");
                        setFlag(false);
                        }}
                        >Not Available</Button>
        
                </ButtonGroup> */}

                            <br />

                        </div>
                        <center>
                            <button className="button20" type="Submit">

                                SUBMIT

                            </button>
                        </center>
                    </div>
                </div>

            </Form>









        </>


    )
}
export default AddOnline;
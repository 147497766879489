




import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyBAKyl95LXGSwyahQh3U8ARaw60Ch0O8F8",
    authDomain: "nadia-college-of-pharmacy.firebaseapp.com",
    projectId: "nadia-college-of-pharmacy",
    storageBucket: "nadia-college-of-pharmacy.appspot.com",
    messagingSenderId: "886374442817",
    appId: "1:886374442817:web:d15434e570b269fa0463c0",
    measurementId: "G-ZK5JCTM40L"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;






import React from 'react'

import Navbar from "../inc/Navbar";
import Slider2 from "../inc/Slider2";
import ScrollToTop from "react-scroll-to-top";
import {Link} from "react-router-dom";
import Nadiacollegegate from "../images/card2.jpg";
import Lift from "../images/liftcard3.jpg"

import Admissionform_badiacollegeofpharmacy from "../images/admissionform_badiacollegeofpharmacy.pdf"

function Home() {
  return (
    <div>
      <Navbar/>

      <br/>
      <br/>
    
<ScrollToTop smooth top="100" color="grey" />
      <Slider2/>
                     
    <div className="album py-3">
        
        <div className="container">

            <div className="row">
            <div className="col-md-8">
            <div className="card">
            <div style={{color:"darkblue"}} className="card-header-primary"><center>Nadia College of Pharmacy</center></div>


  <div class="card-body">
    
    
    <p class="card-text">Please Give below Offline/Onlien Link click & Form Fill up start content.</p>
    <div className="d-flex justify-content-between align-items-center">
    <a href={Admissionform_badiacollegeofpharmacy} target="_blank" className="button1 mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
</svg> Offline Form</a>

<Link to="/Online_Apply"  className="button2 mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
</svg> Online Apply</Link>
    </div>
    <div className="d-flex justify-content-between align-items-center">
    <Link to="/Gallery"  className="button2 mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
</svg> Gallery Page</Link>
</div>
  </div>
</div>
              </div>

             
              <div className="col-md-4">
            <div class="card">
  <img src={Lift} class="card-img-top" alt="nadiacollegeofparmacy"/>
  <div class="card-body">
   <h5 style={{color:"purple"}} class="card-title"><center>Lift</center></h5>
  </div>
</div>
              </div>
              </div>
              </div>
              </div>
    </div>
  )
}

export default Home
